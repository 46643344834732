<template>
  <div class="order-container">
    <div class="total-card">
      <div
        class="item"
        :style="{ backgroundImage: `url(${require('@/assets/bg/order/kejiesuan.png')})` }"
      >
        <div class="title">
          <div class="text">可结算(元)</div>
        </div>
        <div class="data">
          <div class="text">{{ totalCheckOut.can_check_out }}</div>
        </div>
      </div>
      <div
        class="item"
        :style="{ backgroundImage: `url(${require('@/assets/bg/order/yijiesuan.png')})` }"
      >
        <div class="title">
          <div class="text">已结算(元)</div>
        </div>
        <div class="data">
          <div class="text">{{ totalCheckOut.already_check_out }}</div>
        </div>
      </div>

      <div
        class="item"
        :style="{ backgroundImage: `url(${require('@/assets/bg/order/ljsy.png')})` }"
      >
        <div class="title">
          <div class="text">累计收益金额(元)</div>
        </div>
        <div class="data">
          <div class="text">{{ totalCheckOut.total_income }}</div>
        </div>
      </div>
    </div>

    <div class="order-info">
      <div class="title">订单信息</div>

      <div class="table">
        <div class="table-data">
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            header-row-class-name="home-table-header"
            height="500"
          >
            <el-table-column prop="start_end_day" label="结算周期" width="247" align="center">
            </el-table-column>
            <el-table-column prop="share_price" label="周期内订单分成总额" width="247" align="center">
            </el-table-column>
            <el-table-column
              prop="refund_price"
              label="超时退款扣除金额"
              width="247"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="other_price_str" label="其他增减款项及说明" align="center">
            </el-table-column>
            <el-table-column
              prop="real_share_price"
              label="实际结算金额"
              width="247"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="payment_status_str" label="结算状态" width="247" align="center">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.payment_status_str === '可结算'"
                  class="checkout_table_not_payment"
                  >可结算</span
                >

                <span
                  v-if="scope.row.payment_status_str === '已结算'"
                  class="checkout_table_has_payment"
                >
                  已结算
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="page">
          <my-pagination
            :total="totalCount"
            :pageSize="pageSize"
            :pagerCount="pagerCount"
            @current-change="handlePageChange"
          >
          </my-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyPagination from '@/components/pagination/pagination.vue';
import checkoutApi from '@/api/checkout.js';
export default {
  name: 'HomeCheckout',

  components: {
    MyPagination,
  },

  data() {
    return {
      totalCheckOut: {
        can_check_out: '0.00',
        already_check_out: '0.00',
        total_income: '0.00',
      },

      // 当前页数
      currentPage: 1,
      // 每页数量
      pageSize: 10,
      // 可选择页数
      pagerCount: 6,
      // 数据总数
      totalCount: 0,
      tableData: [],
    };
  },

  mounted() {
    checkoutApi.total().then((response) => {
      console.log('checkoutApi.total', response);
      this.totalCheckOut = response.data;
    });

    checkoutApi.list({ page: 1, limit: 10 }).then((response) => {
      const tableData = response.data.data;

      let newTableData = tableData.map((item) => {
        const payment_status_str = item.payment_status === 2 ? '已结算' : '可结算';

        // 处理日期
        const start_end_day =
          item.start_day.split('-').join('/') + '~' + item.end_day.split('-').join('/');

        const other_price = item.other_price;

        let other_price_str = '';
        other_price.map((item) => {
          other_price_str += `${item.type === 1 ? '增加' : '扣除'} ¥${item.fee}${
            item.desc ? `(${item.desc})` : ``
          }, `;
        });

        // 如果为空字符串则为 -
        if (other_price_str === '') {
          other_price_str = '-';
        } else {
          other_price_str = other_price_str.substr(0, other_price_str.length - 2);
        }

        return {
          ...item,
          payment_status_str,
          start_end_day,
          other_price_str,
        };
      });
      console.log('newTableData', newTableData);
      this.tableData = newTableData;

      this.totalCount = response.data.total;
    });
  },
  methods: {
    handlePageChange(currentPage) {
      console.log('handlePageChange', currentPage);

      if (currentPage !== this.currentPage) {
        this.currentPage = currentPage;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order-container {
  width: 100%;
}

.total-card {
  display: flex;
  justify-content: center;
  gap: 26px;
  width: 100%;

  margin-bottom: 24px;

  > .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 476px;
    height: 156px;
    border-radius: 16px;
    background: linear-gradient(327deg, #009cff 0%, #0063ff 100%);
    color: #191b27;

    &:nth-child(1) {
      color: #ffffff;
    }

    .title {
      display: flex;
      margin-top: 28px;
      margin-left: 32px;

      font-size: 18px;
      font-weight: 600;
    }

    .data {
      display: flex;
      justify-content: center;
      margin-bottom: 36px;

      .text {
        font-size: 44px;
        font-weight: 600;
        font-family: Akrobat-SemiBold, Akrobat;
      }
    }
  }
}

.order-info {
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: #ffffff;
  border-radius: 16px;
  padding-bottom: 30px;

  > .title {
    height: 56px;
    line-height: 56px;
    font-size: 18px;
    font-weight: 600;
    color: #191b27;
    margin-bottom: 13px;
    margin-left: 32px;
  }

  .table {
    display: flex;
    flex-direction: column;
    // align-items: center;
    .table-data {
      margin-bottom: 38px;
    }
    .page {
    }
  }
}
</style>

<style lang="scss">
.checkout_table_not_payment {
  font-size: 14px;
  font-weight: 400;
  color: #0080ff;
}

.checkout_table_has_payment {
  font-size: 14px;
  font-weight: 400;
  color: #999fb5;
}
</style>
