import Vue from 'vue';
import Router from 'vue-router';

import HomeParent from '@/views/home/index';
import HomeIndex from '@/views/home/index/index';
import HomeCheckout from '@/views/home/checkout/index';
import HomeFlow from '@/views/home/flow/index';

import { getToken } from '@/utils/auth';
Vue.use(Router);

const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    meta: {
      title: '登录',
    },
  },

  {
    path: '/',
    redirect: '/home/index',
  },

  {
    path: '/home',
    component: HomeParent,
    redirect: '/home/index',
    children: [
      {
        path: 'index',
        name: 'index',
        component: HomeIndex,
        meta: {
          title: '首页',
        },
      },
      {
        path: 'checkout',
        name: 'checkout',
        component: HomeCheckout,
        meta: {
          title: '结算单',
        },
      },
      {
        path: 'flow',
        name: 'flow',
        component: HomeFlow,
        meta: {
          title: '结算流程',
        },
      },
    ],
  },
];

const createRouter = () =>
  new Router({
    routes: constantRoutes,
  });

const router = createRouter();

const whiteList = ['/login'];

function getTitle(title) {
  return `${title} - 聚题库合作方后台`;
}

router.beforeEach((to, from, next) => {
  console.log('beforeEach');

  document.title = getTitle(to.meta.title);

  const hasToken = getToken();

  if (hasToken) {
    if (to.path === '/login') {
      // 如果已经登录，还是访问的login页面，则自动跳转到主页
      next({
        path: '/home/index',
      });
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      //白名单则允许跳转
      next();
    } else {
      // 跳转登录
      next({
        path: '/login',
      });
    }
  }
  next();
});

export default router;
