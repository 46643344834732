import Vue from 'vue';
import App from './App.vue';

import {
  DatePicker,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Table,
  TableColumn,
  Message,
  MessageBox,
} from 'element-ui';

// Vue.config.productionTip = false

import router from './router';
import store from './store';
import './theme-chalk/src/icon.scss';
import './theme-chalk/src/date-picker.scss';

import './theme-chalk/src/dropdown-item.scss';
import './theme-chalk/src/dropdown-menu.scss';
import './theme-chalk/src/dropdown.scss';

import './theme-chalk/src/table.scss';
import './theme-chalk/src/table-column.scss';

import './theme-chalk/src/message.scss';
import './theme-chalk/src/message-box.scss';

// import './theme-chalk/src/pagination.scss';

// import 'element-ui/lib/theme-chalk/index.css';

Vue.use(DatePicker);

Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);

Vue.use(Table);
Vue.use(TableColumn);

Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
