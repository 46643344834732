<template>
  <div class="my-pagination">
    <button class="btn" @click="prev" :disabled="internalCurrentPage <= 1">上一页</button>
    <my-pager
      :currentPage="internalCurrentPage"
      :pageCount="internalPageCount"
      :pagerCount="pagerCount"
      @change="handleCurrentChange"
    ></my-pager>
    <button class="btn" @click="next" :disabled="internalCurrentPage === internalPageCount">
      下一页
    </button>
    <button class="btn" :disabled="internalCurrentPage === internalPageCount" @click="last">
      末页
    </button>

    <div class="total">共{{ total }}条记录</div>
  </div>
</template>

<script>
import MyPager from './pager.vue';
export default {
  name: 'MyPagination',
  components: {
    MyPager,
  },

  props: {
    // 一页有多少数据
    pageSize: {
      type: Number,
      default: 7,
    },
    // 可选择的页数数量
    pagerCount: {
      type: Number,
      default: 6,
    },
    // 当前在多少页
    currentPage: {
      type: Number,
      default: 1,
    },
    // 数据总数
    total: Number,
  },

  data() {
    return {
      internalCurrentPage: 1,
      internalPageSize: 0,
    };
  },

  computed: {
    internalPageCount() {
      return Math.max(1, Math.ceil(this.total / this.internalPageSize));
    },
  },

  watch: {
    pageSize: {
      immediate: true,
      handler(val) {
        this.internalPageSize = val;
      },
    },

    currentPage: {
      immediate: true,
      handler(val) {
        this.internalCurrentPage = val;
      },
    },
  },

  methods: {
    prev() {
      const newVal = this.internalCurrentPage - 1;
      this.internalCurrentPage = newVal;
      this.emitChange();
    },

    next() {
      const newVal = this.internalCurrentPage + 1;

      this.internalCurrentPage = newVal;
      this.emitChange();
    },

    last() {
      this.internalCurrentPage = this.internalPageCount;
      this.emitChange();
    },

    handleCurrentChange(val) {
      this.internalCurrentPage = val;
      this.emitChange();
    },

    emitChange() {
      this.$emit('current-change', this.internalCurrentPage);
      // this.$nextTick(() => {
      //   this.$emit('current-change', this.internalCurrentPage);
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .btn {
    width: 82px;
    height: 36px;
    background: #f7f8fb;
    border-radius: 4px;
    cursor: pointer;
    border: 0px;
    user-select: none;
  }

  .total {
    width: 109px;
    height: 36px;
    background: rgba(0, 128, 255, 0.05);
    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 12px;
    font-weight: 400;
    color: #0080ff;
  }
}
</style>
