import request from '@/utils/request';
import qs from 'qs';

function login(data) {
  return request({
    url: '/api/partner-manage/login',
    method: 'post',
    data: qs.stringify(data),
  });
}

function userInfo() {
  return request({
    url: '/api/partner-manage/me',
    method: 'get',
  });
}

// 信息确认
function accountConfirm() {
  return request({
    url: '/api/partner-manage/account-confirm',
    method: 'post',
  });
}

export default {
  login,
  userInfo,
  accountConfirm
};
