<template>
  <div class="not-sure-container">
    <div class="not-sure">
      <div class="top">
        <div class="title">确认收款账户信息</div>

        <div class="desc">
          为保证账户资金安全性及后续结算，请先确认收款账户信息
          <span class="red">（一经确认无法修改）</span
          >，若有错误请联系对接人员尽快进行修改后完成确认。未确认将无法进行结算。
        </div>
      </div>
      <div class="main">
        <my-bank-card
          :title="userInfo.bank"
          :cardNumber="userInfo.bank_number"
          :companyName="userInfo.account_name"
        ></my-bank-card>
      </div>
      <div class="bottom">
        <div class="sure-btn" @click="openSureDialog">确认无误</div>
        <div class="info">账号不对，联系对接人</div>
      </div>
    </div>

    <div class="flow">
      <my-time-line>
        <my-time-line-item :title="dataFlowObj['1_title']" dotTitle="1">
          <span v-html="dataFlowObj['1_content']"></span>
        </my-time-line-item>
        <my-time-line-item :title="dataFlowObj['2_title']" dotTitle="2">
          <span v-html="dataFlowObj['2_content']"></span>
        </my-time-line-item>
        <my-time-line-item :title="dataFlowObj['3_title']" dotTitle="3">
          <span v-html="dataFlowObj['3_content']"></span>
        </my-time-line-item>

        <my-time-line-item :title="dataFlowObj['4_title']" dotTitle="4" :tailHidden="true">
          <span v-html="dataFlowObj['4_content']"></span>
        </my-time-line-item>
      </my-time-line>
    </div>
  </div>
</template>

<script>
import SureAccountDialog from '@/components/dialog/sureAccountDialog.vue';
import MyBankCard from '@/components/bankCard/myBankCard.vue';
import MyBankBlueCard from '@/components/bankCard/myBankBlueCard.vue';
import MyTimeLine from '@/components/timelines/timeLine.vue';
import MyTimeLineItem from '@/components/timelines/timeLineItem.vue';

import userApi from '@/api/user.js';
export default {
  name: 'HasSureCard',
  components: {
    MyTimeLine,
    MyTimeLineItem,
    MyBankCard,
    MyBankBlueCard,
    SureAccountDialog,
  },

  props: {
    userInfo: {
      type: Object,
      default: {},
    },

    dataFlowObj: {
      type: Object,
      default: {
        '1_title': '',
        '1_content': '',
        '2_title': '',
        '2_content': '',
        '3_title': '',
        '3_content': '',
        '4_title': '',
        '4_content': '',
      },
    },
  },

  computed: {},

  mounted() {},

  methods: {
    openSureDialog() {
      this.$confirm('请确认收款账号无误，确认后将无法修改，请谨慎操作!', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 点击确认
          userApi.accountConfirm().then((response) => {
            if (response.code === 200) {
              // 确认成功
              console.log('确认成功');
              this.$store.commit('user/SET_IS_CONFIRM', 1);
            } else {
              // 失败
            }
          });
        })
        .catch(() => {
          // 点击取消
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.not-sure-container {
  display: flex;
  justify-content: center;
  gap: 20px;

  width: 100%;
  height: 100%;
}

.not-sure {
  display: flex;
  flex-direction: column;
  width: 778px;
  margin-bottom: 73px;
  padding-top: 26px;
  padding-left: 41px;
  padding-right: 46px;
  border-radius: 16px;

  background-color: rgba(255, 255, 255, 1);
  .top {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;

    .title {
      margin-bottom: 25px;
      font-size: 24px;
      font-weight: 600;
      color: #191b27;
    }

    .desc {
      font-size: 18px;
      font-weight: 400;
      color: #465069;

      .red {
        color: rgba(254, 23, 23, 1);
      }
    }
  }

  .main {
    display: flex;
    justify-content: center;
    margin-bottom: 140px;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;

    .sure-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      margin-bottom: 32px;

      width: 258px;
      height: 56px;
      background: #0080ff;
      border-radius: 28px;

      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
    }

    .info {
      font-size: 16px;
      font-weight: 400;
      color: #fe1717;

      text-decoration: underline;
    }
  }
}

.flow {
  display: flex;
  flex-direction: column;
  width: 778px;

  margin-bottom: 73px;

  padding-left: 31px;
  padding-top: 44px;
  padding-right: 47px;
  padding-bottom: 19px;
  border-radius: 16px;

  background-color: rgba(255, 255, 255, 1);
}
</style>
