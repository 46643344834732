<template>
  <div class="sure-account-dialog-wrapper" @click="wrapperClick" v-if="visible">
    <div class="dialog" @click="dialogClick">
      <div class="close" @click="closeClick"></div>

      <div class="main">
        <div class="title">
          <div class="icon"></div>
          <div class="text">请先确认收款账号</div>
        </div>
        <div class="desc">
          为保证账户资金安全性及后续结算，请先确认收款账户信息<span class="red"
            >（一经确认无法修改）</span
          >，若有错误请联系对接人员尽快进行修改后完成确认。未确认将无法进行结算。
        </div>
        <div class="card">
          <my-mini-bank-card
            :title="userInfo.bank"
            :cardNumber="userInfo.bank_number"
            :companyName="userInfo.account_name"
          ></my-mini-bank-card>
        </div>
        <div class="btn">
          <div class="btn-sure" @click="clickSureBtn">确认无误</div>
          <div class="info" @click="clickInfo">账号不对，联系对接人</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyMiniBankCard from '@/components/bankCard/myMiniBankCard.vue';

export default {
  name: 'SureAccountDialog',

  components: { MyMiniBankCard },

  props: {
    visible: Boolean,
  },

  data() {
    return {};
  },

  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
  },

  methods: {
    wrapperClick(event) {
      this.$emit('closeDialog');
    },
    dialogClick(event) {
      event.stopPropagation();
    },

    closeClick(event) {
      console.log('closeClick');
      this.$emit('closeDialog');
      event.stopPropagation();
    },

    clickSureBtn(event) {
      this.$confirm('请确认收款账号无误，确认后将无法修改，请谨慎操作!', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          // 点击确认
          this.$emit('clickSureBtn');
        })
        .catch(() => {
          // 点击取消
        });

      event.stopPropagation();
    },

    clickInfo(event) {
      this.$emit('clickInfo');
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
.sure-account-dialog-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  .dialog {
    width: 792px;
    height: 539px;
    border-radius: 16px;
    background: #ffffff;
    position: relative;

    .close {
      position: absolute;
      right: 24px;
      top: 24px;
      width: 20px;
      height: 20px;
      background-image: url('@/assets/bg/sure-dialog/close.png');
      cursor: pointer;
    }

    .main {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        margin-top: 39px;
        display: flex;
        align-items: center;
        gap: 10px;
        .icon {
          width: 29px;
          height: 26px;
          background-image: url('@/assets/bg/sure-dialog/warn.png');
        }

        .text {
          font-size: 22px;
          font-weight: 600;
          color: #191b27;
        }
      }

      .desc {
        font-size: 16px;
        font-weight: 400;
        color: #465069;
        padding: 0 50px;

        margin-top: 28px;
        .red {
          color: #fe1717;
        }
      }

      > .card {
        margin-top: 37px;
        padding: 0;
      }

      .btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        // gap: 23px;
        margin-top: 28px;
        margin-bottom: 35px;

        .btn-sure {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 258px;
          height: 50px;
          background: #0080ff;
          border-radius: 25px;

          margin-bottom: 23px;

          font-size: 16px;
          font-weight: 600;
          color: #ffffff;

          cursor: pointer;
        }

        .info {
          font-size: 16px;
          font-weight: 400;
          color: #fe1717;
          text-decoration: underline;
          line-height: 22px;
        }
      }
    }
  }
}
</style>
