<template>
  <ul class="my-pager" @click="onPagerClick">
    <li :class="{ active: currentPage === 1 }" v-if="pageCount > 0" class="number">1</li>
    <li
      v-for="pager in pagers"
      :key="pager"
      :class="{ active: currentPage === pager }"
      class="number"
    >
      {{ pager }}
    </li>
    <li :class="{ active: currentPage === pageCount }" class="number" v-if="pageCount > 1">
      {{ pageCount }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'MyPager',
  props: {
    currentPage: Number,
    // 所有的数据一共有多少页
    pageCount: Number,
    // 可选择页数数量
    pagerCount: Number,

    disabled: Boolean,
  },

  computed: {
    pagers() {
      const pagerCount = this.pagerCount;
      const halfPagerCount = (pagerCount - 1) / 2;

      const currentPage = Number(this.currentPage);
      const pageCount = Number(this.pageCount);

      let showPrevMore = false;
      let showNextMore = false;

      if (pageCount > pagerCount) {
        if (currentPage > pagerCount - halfPagerCount) {
          showPrevMore = true;
        }

        if (currentPage < pageCount - halfPagerCount) {
          showNextMore = true;
        }
      }

      const array = [];

      if (showPrevMore && !showNextMore) {
        const startPage = pageCount - (pagerCount - 2);
        for (let i = startPage; i < pageCount; i++) {
          array.push(i);
        }
      } else if (!showPrevMore && showNextMore) {
        for (let i = 2; i < pagerCount; i++) {
          array.push(i);
        }
      } else if (showPrevMore && showNextMore) {
        const offset = Math.floor(pagerCount / 2) - 1;
        for (let i = currentPage - offset; i <= currentPage + offset; i++) {
          array.push(i);
        }
      } else {
        for (let i = 2; i < pageCount; i++) {
          array.push(i);
        }
      }

      return array;
    },
  },

  data() {
    return {
      showPrevMore: false,
      showNextMore: false,
    };
  },

  mounted() {
    console.log('pageCount', this.pageCount);
  },

  methods: {
    onPagerClick(event) {
      const target = event.target;
      if (target.tagName === 'UL') {
        return;
      }

      let newPage = Number(target.textContent);
      const pageCount = this.pageCount;
      const currentPage = this.currentPage;

      if (!isNaN(newPage)) {
        if (newPage < 1) {
          newPage = 1;
        }

        if (newPage > pageCount) {
          newPage = pageCount;
        }
      }

      if (newPage !== currentPage) {
        this.$emit('change', newPage);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my-pager {
  display: flex;
  justify-content: center;
  gap: 8px;
  user-select: none;
  list-style: none;
  margin: 0;
  padding: 0;
  > .number {
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    width: 36px;
    height: 36px;
    background: #f7f8fb;
    border-radius: 4px;

    font-size: 14px;
    font-weight: 400;
    color: #191b27;
    &.active {
      background: #0080ff;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
