import { render, staticRenderFns } from "./pager.vue?vue&type=template&id=6a8d0722&scoped=true&"
import script from "./pager.vue?vue&type=script&lang=js&"
export * from "./pager.vue?vue&type=script&lang=js&"
import style0 from "./pager.vue?vue&type=style&index=0&id=6a8d0722&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a8d0722",
  null
  
)

export default component.exports