<script>
export default {
  name: 'MyTimeLine',

  render() {
    let slots = this.$slots.default || [];

    const classes = {
      timeline: true,
    };

    return <ul class={classes}>{slots}</ul>;
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  margin: 0;
  padding: 0;
  font-size: 14px;
  list-style: none;
}
</style>
