<template>
  <div class="flow-container">
    <has-sure-card
      :userInfo="userInfo"
      :dataFlowObj="dataFlowObj"
      v-if="userInfo.is_confirm !== 0"
    ></has-sure-card>
    <not-sure-card
      :userInfo="userInfo"
      :dataFlowObj="dataFlowObj"
      v-if="userInfo.is_confirm === 0"
    ></not-sure-card>
  </div>
</template>

<script>
import HasSureCard from './hasSureCard.vue';
import NotSureCard from './notSureCard.vue';

import checkoutApi from '@/api/checkout';

export default {
  name: 'HomeFlow',
  components: {
    HasSureCard,
    NotSureCard,
  },

  data() {
    return {
      dataFlowObj: {
        '1_title': '',
        '1_content': '',
        '2_title': '',
        '2_content': '',
        '3_title': '',
        '3_content': '',
        '4_title': '',
        '4_content': '',
      },
    };
  },

  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
  },

  mounted() {
    checkoutApi.process().then((response) => {
      console.log('response', response);
      const data = response.data;

      const getDataFlowObj = {};

      data.map((item) => {
        getDataFlowObj[item.key] = item.value;
      });

      console.log(getDataFlowObj);

      this.dataFlowObj = getDataFlowObj;
    });
  },

  methods: {
   
  },
};
</script>
<style lang="scss" scoped>
.flow-container {
  display: flex;
  justify-content: center;
  width: 100%;

  overflow-y: auto;
 
}
</style>
