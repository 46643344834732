<template>
  <div class="index-container">
    <div class="tips" v-if="showTips">
      <div class="info">
        <div class="icon"></div>
        <div class="text">
          请尽快完成收款账号确认，无误则点击确认无误按钮，有误则联系对接人员修改后再登录后台确认，否则将影响正常结算
        </div>
      </div>
      <!-- 去确认按钮 -->
      <div class="btn" @click="gotoFlowPage"></div>
    </div>
    <div class="wrapper">
      <div class="total-card">
        <div class="item" :style="{ backgroundImage: `url(${require('@/assets/bg/toda.png')})` }">
          <div class="title">今日</div>
          <div class="data">
            <div class="item">
              <div class="num">{{ totalData.today.order_count }}</div>
              <div class="title">订单数</div>
            </div>
            <div class="item">
              <div class="num">{{ totalData.today.pay_amount }}</div>
              <div class="title">用户实付金额(元)</div>
            </div>
          </div>
        </div>

        <div class="item" :style="{ backgroundImage: `url(${require('@/assets/bg/lasta.png')})` }">
          <div class="title">昨日</div>
          <div class="data">
            <div class="item">
              <div class="num">{{ totalData.yesterDay.order_count }}</div>
              <div class="title">订单数</div>
            </div>
            <div class="item">
              <div class="num">{{ totalData.yesterDay.pay_amount }}</div>
              <div class="title">用户实付金额(元)</div>
            </div>
          </div>
        </div>

        <div class="item" :style="{ backgroundImage: `url(${require('@/assets/bg/month.png')})` }">
          <div class="title">本月</div>
          <div class="data">
            <div class="item">
              <div class="num">{{ totalData.month.order_count }}</div>
              <div class="title">订单数</div>
            </div>
            <div class="item">
              <div class="num">{{ totalData.month.pay_amount }}</div>
              <div class="title">用户实付金额(元)</div>
            </div>
          </div>
        </div>
      </div>

      <div class="order-info">
        <div class="title">订单信息</div>

        <div class="tools">
          <el-date-picker
            :clearable="false"
            v-model="dateRangeValue"
            @change="handleDateChange"
            type="daterange"
            range-separator="-"
            format="yyyy/MM/dd"
            prefix-icon="icon-date"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>

          <div class="status">
            <div class="title">订单状态</div>

            <el-dropdown @command="handleSelectMenuItem">
              <span class="el-dropdown-link">
                {{ payStatus[currentPayStatus] }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="0">{{ payStatus[0] }}</el-dropdown-item>
                <el-dropdown-item command="1">{{ payStatus[1] }}</el-dropdown-item>
                <el-dropdown-item command="2">{{ payStatus[2] }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div class="find-btn" @click="handleFindClick">查询</div>

          <div class="reset" @click="handleReset">
            <div class="icon"></div>
            <div class="text">重置</div>
          </div>
        </div>

        <div class="table">
          <div class="table-data">
            <el-table
              :data="tableData"
              stripe
              style="width: 100%"
              header-row-class-name="home-table-header"
            >
              <el-table-column prop="id" label="订单ID" width="153" align="center">
              </el-table-column>
              <el-table-column prop="mobile" label="用户账号" width="153" align="center">
              </el-table-column>
              <el-table-column prop="good_name" label="商品名称" align="center"> </el-table-column>
              <el-table-column prop="pay_type_str" label="支付方式" width="135" align="center">
              </el-table-column>
              <el-table-column prop="fee" label="实付金额" width="123" align="center">
              </el-table-column>
              <el-table-column prop="pay_at" label="支付时间" width="203" align="center">
              </el-table-column>
              <el-table-column prop="pay_status_str" label="订单状态" width="123" align="center">
              </el-table-column>
              <el-table-column
                prop="qorderpm.share_price"
                label="分成金额"
                width="143"
                align="center"
              >
              </el-table-column>
              <el-table-column
                prop="qorderpm.share_day"
                label="分成金额生成时间"
                width="221"
                align="center"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <div class="page">
      <my-pagination
        :total="totalCount"
        :pageSize="pageSize"
        :pagerCount="pagerCount"
        :currentPage="currentPage"
        @current-change="handlePageChange"
      >
      </my-pagination>
    </div>

    <sure-account-dialog
      :visible="sureAccountDialogVisible"
      @closeDialog="closeSureAccountDialog"
      @clickSureBtn="clickSureAccountDialogBtn"
      @clickInfo="clickSureAccountDialogInfo"
    ></sure-account-dialog>
  </div>
</template>
<script>
import MyPagination from '@/components/pagination/pagination.vue';
import SureAccountDialog from '@/components/dialog/sureAccountDialog.vue';
import userApi from '@/api/user.js';
import orderApi from '@/api/order.js';
import { dateToString, getMonth, getToday, getYesteday, getSevenDay } from './date';
import mockData from './mockData';

export default {
  name: 'HomeIndex',
  components: {
    MyPagination,
    SureAccountDialog,
  },

  data() {
    return {
      dateRangeValue: [],

      // 当前页数
      currentPage: 1,
      // 每页数量
      pageSize: 10,
      // 可选择页数
      pagerCount: 6,
      // 数据总数
      totalCount: 0,
      tableData: [],

      currentPayStatus: 0,
      payStatus: {
        0: '不限',
        1: '已支付',
        2: '已退款',
      },

      totalData: {
        today: {
          order_count: '0',
          pay_amount: '0',
        },

        yesterDay: {
          order_count: '0',
          pay_amount: '0',
        },
        month: {
          order_count: '0',
          pay_amount: '0',
        },
      },

      sureAccountDialogVisible: false,
    };
  },

  computed: {
    showTips() {
      return this.$store.state.user.userInfo.is_confirm === 0 ? true : false;
    },
  },

  watch: {
    showTips: {
      immediate: true,
      handler(val) {
        this.sureAccountDialogVisible = val;
      },
    },
  },

  mounted() {
    this.requestTotalData();

    // 默认将近七天作为查询日期范围
    const [sevenDayStart, sevenDayEnd] = getSevenDay();
    this.dateRangeValue = [new Date(sevenDayStart), new Date(sevenDayEnd)];
    this.requestTableData(sevenDayStart, sevenDayEnd);
  },

  methods: {
    requestTotalData() {
      // 获取本月数据
      const [monthStart, monthEnd] = getMonth();
      orderApi
        .amounts({
          start_time: monthStart,
          end_time: monthEnd,
        })
        .then((response) => {
          this.totalData.month = response.data;
        });

      // 获取昨日数据
      const [yesterdayStart, yesterdayEnd] = getYesteday();
      orderApi
        .amounts({
          start_time: yesterdayStart,
          end_time: yesterdayEnd,
        })
        .then((response) => {
          this.totalData.yesterDay = response.data;
        });

      // 获取今日数据
      const [todayStart, todayEnd] = getToday();
      orderApi
        .amounts({
          start_time: todayStart,
          end_time: todayEnd,
        })
        .then((response) => {
          this.totalData.today = response.data;
        });
    },

    requestTableData(start, end) {
      return orderApi
        .list({
          start_time: start,
          end_time: end,
          pay_status: this.currentPayStatus,
          page: this.currentPage,
          limit: this.pageSize,
        })
        .then((response) => {
          const tableData = response.data.data;

          const newTableData = tableData.map((item) => {
            // 处理日期数据
            let pay_at = item.pay_at;
            pay_at = pay_at.replaceAll('-', '/');

            let qorderpm = item.qorderpm;

            if (qorderpm === null) {
              // 处理分成数据为空的情况
              qorderpm = {
                share_price: '-',
                share_day: '-',
              };
            } else {
              // 处理日期数据
              let myShareDay = qorderpm.share_day.replaceAll('-', '/');
              // 如果日期为空则使用-
              if (myShareDay === '') {
                myShareDay = '-';
              }

              // 当分成金额为0.00时则使用-
              if (qorderpm.share_price === '0.00') {
                qorderpm.share_price = '-';
              }

              qorderpm = {
                ...qorderpm,
                share_day: myShareDay,
              };
            }

            return {
              ...item,
              pay_at,
              qorderpm,
            };
          });

          this.tableData = newTableData;
          this.totalCount = response.data.total;
        });

      // // 模拟数据
      // this.tableData = mockData
    },

    handleReset() {
      this.currentPage = 1;
      this.currentPayStatus = 0;

      // 默认将近七天作为查询日期范围
      const [sevenDayStart, sevenDayEnd] = getSevenDay();
      this.dateRangeValue = [new Date(sevenDayStart), new Date(sevenDayEnd)];
      this.requestTableData(sevenDayStart, sevenDayEnd);
    },

    handlePageChange(currentPage) {
      console.log('handlePageChange', currentPage);

      if (currentPage !== this.currentPage) {
        this.currentPage = currentPage;

        const [startDate, endDate] = this.dateRangeValue;
        const startDateStr = dateToString(startDate);
        const endDateStr = dateToString(endDate);
        this.requestTableData(startDateStr, endDateStr);
      }
    },

    handleSelectMenuItem(command) {
      console.log('command', command);
      this.currentPayStatus = command;
    },

    handleDateChange(dateRange) {
      console.log('handleDateChange', dateRange);
      console.log(this.dateRangeValue);
    },

    handleFindClick(event) {
      // 重置页数
      this.currentPage = 1;

      const [startDate, endDate] = this.dateRangeValue;
      const startDateStr = dateToString(startDate);
      const endDateStr = dateToString(endDate);
      this.requestTableData(startDateStr, endDateStr);
    },

    gotoFlowPage() {
      this.$router.push({
        name: 'flow',
      });
    },
    closeSureAccountDialog() {
      this.sureAccountDialogVisible = false;
    },
    clickSureAccountDialogBtn() {
      console.log('点击确认');
      userApi.accountConfirm().then((response) => {
        if (response.code === 200) {
          // 确认成功
          this.$store.commit('user/SET_IS_CONFIRM', 1);
          this.sureAccountDialogVisible = false;
        } else {
          // 确认失败
        }
      });
    },

    clickSureAccountDialogInfo() {
      console.log('点击信息');
    },
  },
};
</script>

<style lang="scss" scoped>
.index-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tips {
  width: 100%;
  height: 52px;
  background-color: rgba(255, 218, 218, 0.5);
  border-radius: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
  .info {
    margin-left: 26px;
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    .icon {
      width: 22px;
      height: 19px;
      background-image: url('@/assets/icon/home-tips.png');
    }

    .text {
      font-size: 15px;
      font-weight: 300;
      color: #ff0000;
    }
  }

  .btn {
    cursor: pointer;
    width: 106px;
    height: 36px;
    margin-right: 10px;
    background-image: url('@/assets/img/home-ququereng.png');
  }
}

.wrapper {
  overflow-y: auto;
  height: 75vh;

  .total-card {
    display: flex;
    margin-bottom: 21px;
    gap: 26px;
    width: 100%;
    justify-content: center;

    > .item {
      display: flex;
      flex-direction: column;

      width: 476px;
      height: 204px;

      border-radius: 16px;
      > .title {
        font-size: 20px;
        font-weight: 600;
        margin-top: 26px;
        margin-left: 32px;
        color: #191b27;
      }

      .data {
        display: flex;
        justify-content: center;
        gap: 142px;
        margin-top: 36px;

        > .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 6px;

          .num {
            font-family: Akrobat-SemiBold, Akrobat;
            font-size: 44px;
            font-weight: 600;
          }

          .title {
            display: flex;
            justify-content: center;
            font-size: 16px;
            font-weight: 400;
            color: #465069;
          }
        }
      }

      &:nth-child(3) {
        > .title {
          color: #ffffff;
        }

        .data {
          > .item {
            .num {
              color: #ffffff;
            }
            .title {
              color: #ffffff;
            }
          }
        }
      }
    }
  }

  .order-info {
    display: flex;
    flex-direction: column;
    width: 100%;

    background-color: #ffffff;
    border-radius: 16px;
    padding-bottom: 30px;

    > .title {
      height: 56px;
      line-height: 56px;
      font-size: 18px;
      font-weight: 600;
      color: #191b27;
      margin-bottom: 13px;
      margin-left: 32px;
    }

    .tools {
      padding-left: 32px;
      display: flex;
      align-items: center;
      gap: 32px;
      height: 44px;

      margin-bottom: 25px;

      .status {
        width: 225px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-size: 14px;
        background: #f7f8fb;
        border-radius: 8px;

        > .title {
          font-size: 14px;
          font-weight: 400;
          color: #191b27;
        }
      }

      .find-btn {
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        width: 87px;
        height: 44px;
        background: #0080ff;
        border-radius: 26px;

        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }

      .reset {
        display: flex;
        gap: 6px;
        cursor: pointer;
        .icon {
          width: 22px;
          height: 22px;
          background-image: url('@/assets/icon/home-reset.png');
        }

        .text {
          font-size: 14px;
          font-weight: 400;
          color: #000000;
        }
      }
    }

    .table {
      display: flex;
      flex-direction: column;
      // align-items: center;
      .table-data {
        margin-bottom: 38px;
      }
    }
  }
}

.page {
  margin-top: 10px;
  // position: fixed;
  // bottom: 50px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #0080ff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>

<style lang="scss">
.icon-date {
  width: 17px !important;
  height: 17px !important;
  background-image: url('@/assets/icon/icon-date.png');
}

.home-table-header {
  font-size: 14px;
  font-weight: 400;
  color: #191b27;

  .el-table__cell {
    background: RGBA(240, 248, 255, 1) !important;
  }
}
</style>
