export function dateToString(date) {
  var year = date.getFullYear();
  var month = (date.getMonth() + 1).toString();
  var day = date.getDate().toString();
  if (month.length == 1) {
    month = '0' + month;
  }
  if (day.length == 1) {
    day = '0' + day;
  }
  var dateTime = year + '-' + month + '-' + day;
  return dateTime;
}

// 获取本月
export const getMonth = () => {
  // 本月第一天
  const firstDate = new Date();
  firstDate.setDate(1);

  // 本月最后一天
  const date = new Date();
  const lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return [dateToString(firstDate), dateToString(lastDate)];
};

// 获取昨天
export const getYesteday = () => {
  const date = new Date();

  date.setDate(date.getDate() - 1);

  return [dateToString(date), dateToString(date)];
};

// 获取今日
export const getToday = () => {
  const date = new Date();

  return [dateToString(date), dateToString(date)];
};

// 获取近七天
export const getSevenDay = () => {
  const dateStart = new Date();
  dateStart.setDate(dateStart.getDate() - (7 - 1));

  const dateEnd = new Date();

  return [dateToString(dateStart), dateToString(dateEnd)];
};
