<template>
  <li :class="['timeline-item', { 'no-padding-bottom': tailHidden }]">
    <div class="tail" v-if="!tailHidden"></div>
    <div class="node">{{ dotTitle }}</div>
    <div class="wrapper">
      <div class="title">{{ title }}</div>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'MyTimeLineItem',
  props: {
    title: String,
    dotTitle: String,
    tailHidden: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline-item {
  position: relative;
  padding-bottom: 48px;
  .tail {
    position: absolute;
    left: 13px;
    height: 100%;
    border-left: 1px solid #63ffdf;
  }
  .node {
    // left: -1px;
    width: 28px;
    height: 28px;
    position: absolute;
    background: #00f0be;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
  }

  .wrapper {
    position: relative;
    padding-left: 52px;
    top: 0;
    display: flex;
    flex-direction: column;

    gap: 20px;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: #191b27;
    }

    .content {
      font-size: 16px;
      font-weight: 400;
      color: #465069;
      line-height: 28px;

      .red {
        color: red;
      }
    }
  }
}

.no-padding-bottom {
  padding-bottom: 0px;
}
</style>
