import request from '@/utils/request';
import qs from 'qs';

// 结算单列表
function list({ page, limit }) {
  return request({
    url: '/api/partner-manage/checkout-list',
    method: 'get',
    params: {
      page,
      limit,
    },
  });
}

// 结算单统计
function total() {
  return request({
    url: '/api/partner-manage/checkout-total',
    method: 'get',
  });
}

// 结算单流程
function process() {
  return request({
    url: '/api/partner-manage/checkout-process',
    method: 'get',
  });
}

export default {
  list,
  total,
  process,
};
