import { getToken, setToken, removeToken } from '@/utils/auth';
import userApi from '@/api/user';

const getUserInfo = () => {
  const defaultState = {
    // 合作方名称
    name: '',
    mobile: '',
    // 账号名称
    account_name: '',
    // 开户行
    bank: '',
    // 银行卡号
    bank_number: '',
    // 是否已确认
    is_confirm: undefined,
  };

  const userInfoStr = localStorage.getItem('userInfo');
  if (userInfoStr === null) {
    return defaultState;
  } else {
    return JSON.parse(userInfoStr);
  }
};

const getDefaultState = () => {
  return {
    token: getToken(),

    userInfo: getUserInfo(),
    // // 合作方名称
    // name: '',
    // mobile: '',
    // // 账号名称
    // account_name: '',
    // // 开户行
    // bank: '',
    // // 银行卡号
    // bank_number: '',
    // // 是否已确认
    // is_confirm: undefined,
  };
};

const state = getDefaultState();

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USER_INFO: (state, userInfo) => {
    // state.name = userInfo.name;
    // state.mobile = userInfo.mobile;
    // state.account_name = userInfo.account_name;
    // state.bank = userInfo.bank;
    // state.bank_number = userInfo.bank_number;
    // state.is_confirm = userInfo.is_confirm;
    state.userInfo = userInfo;
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  },

  SET_IS_CONFIRM: (state, is_confirm) => {
    state.userInfo.is_confirm = is_confirm;

    localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
  },
};

const actions = {
  async login({ commit, state }, userInfo) {
    const { mobile, password } = userInfo;

    // 拿到jwt token
    const loginData = await userApi.login({
      mobile,
      password,
    });

    if (loginData.code !== 200) {
      return {
        code: loginData.code,
        message: loginData.message,
      };
    }

    commit('SET_TOKEN', 'Bearer ' + loginData.data.access_token);
    setToken('Bearer ' + loginData.data.access_token);

    // 通过token拿到用户个人信息
    const userInfoData = await userApi.userInfo();
    console.log('userInfoData', userInfoData);
    commit('SET_USER_INFO', userInfoData.data);

    return {
      code: 200,
    };
  },

  async logout({ commit, state }) {
    removeToken();
    commit('RESET_STATE');

    return true;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
