import request from '@/utils/request';
import qs from 'qs';

// 订单统计
function amounts({ start_time, end_time }) {
  return request({
    url: '/api/partner-manage/order-amounts',
    method: 'get',
    params: {
      start_time,
      end_time,
    },
  });
}

/**
 * 订单列表
 * @param pay_status 1: 已支付, 2: 已付款, 0: 全部
 * @returns
 */
function list({ start_time, end_time, pay_status, page, limit }) {
  return request({
    url: '/api/partner-manage/order-list',
    method: 'get',
    params: {
      start_time,
      end_time,
      pay_status,
      page,
      limit,
    },
  });
}

export default {
  amounts,
  list,
};
