<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},

  mounted() {
    // 注入版本用于判断是否更新.
    window.questionVersion = '2022-8-10 12:02:0';
  },
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  font-family: PingFangSC-Light, PingFang SC;
}

body {
  margin: 0px;
}

@font-face {
  font-family: 'Akrobat-SemiBold';
  src: url('@/assets/fonts/Akrobat-SemiBold.otf');
}
</style>
