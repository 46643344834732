<template>
  <div class="container">
    <div class="left-sider">
      <div class="top">
        <router-link class="logo" :to="{ path: '/home/index' }"></router-link>
        <div class="menu">
          <div
            class="item"
            :class="[{ active: currentRouteName === 'index' }]"
            @click="leftMenuClick('index')"
          >
          </div>
          <div
            class="item"
            :class="[{ active: currentRouteName === 'checkout' }]"
            @click="leftMenuClick('checkout')"
          >
          </div>
          <div
            class="item"
            :class="[{ active: currentRouteName === 'flow' }]"
            @click="leftMenuClick('flow')"
          >
          </div>
        </div>
      </div>

      <div class="bottom">
        <div class="quit-login" @click="quitLogin">
          <svg
            width="22"
            height="26"
            viewBox="0 0 22 26"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.79628 0.555542C2.97625 0.555542 3.15565 0.573468 3.3068 0.603338L3.41443 0.627886L16.7595 3.27523C17.8997 3.56489 18.7107 4.55386 18.7738 5.7073L18.7778 5.8523H16.9064C16.9064 5.5148 16.6949 5.21659 16.4199 5.10729L16.3435 5.08228L2.99842 2.43494C2.93237 2.41816 2.86446 2.40967 2.79628 2.40967C2.37678 2.40967 2.03142 2.72414 1.98723 3.12813L1.98246 3.21599V22.7833C1.98246 22.8509 1.99103 22.9182 2.00796 22.9836C2.11216 23.3862 2.50539 23.6396 2.95101 23.5727L16.2947 20.9281C16.6247 20.8443 16.8637 20.5672 16.9012 20.238L16.9064 20.147H18.7778C18.7778 21.3133 18.0123 22.3373 16.8571 22.6941L16.7107 22.7351L3.46323 25.3604C2.02671 25.7254 0.563579 24.8674 0.195231 23.4441C0.153324 23.2822 0.126945 23.1169 0.116374 22.9503L0.111084 22.7833V3.21599C0.111084 1.74667 1.31329 0.555542 2.79628 0.555542Z"
            />
            <path
              d="M19.4758 12.2222C19.9494 12.2222 20.3333 12.5704 20.3333 13C20.3333 13.4027 19.9959 13.7339 19.5635 13.7738L19.4758 13.7778H10.302C9.82838 13.7778 9.44446 13.4295 9.44446 13C9.44446 12.5973 9.78189 12.2661 10.2143 12.2262L10.302 12.2222H19.4758Z"
            />
            <path
              d="M16.678 8.61503C16.9687 8.26445 17.4279 8.24108 17.7411 8.54491L17.8058 8.61503L20.8776 12.3199C21.1682 12.6704 21.1876 13.2243 20.9357 13.602L20.8776 13.6801L17.8058 17.3849C17.4944 17.7606 16.9895 17.7606 16.678 17.3849C16.3874 17.0344 16.368 16.4805 16.6199 16.1028L16.678 16.0247L19.1857 12.9997L16.678 9.97527C16.3874 9.62469 16.368 9.07081 16.6199 8.69313L16.678 8.61503Z"
            />
          </svg>

          <div class="text">退出登录</div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="user-menu">
        <div class="user">
          <div class="head" data-label="头像"></div>
          <div class="info">
            <div class="name">{{ userName }}</div>
            <div class="phone">{{ userMobile }}</div>
          </div>
        </div>
      </div>

      <router-view class="main-wrapper"></router-view>
    </div>
  </div>
</template>
<script>
import MyPagination from '@/components/pagination/pagination.vue';

export default {
  name: 'Home',
  components: {
    MyPagination,
  },

  data() {
    return {
      currentRouteName: '',
    };
  },

  computed: {
    userName() {
      return this.$store.state.user.userInfo.name;
    },

    userMobile() {
      return this.$store.state.user.userInfo.mobile;
    },
  },

  beforeRouteUpdate(to, from, next) {
    this.currentRouteName = to.name;
    next();
  },

  created() {
    this.currentRouteName = this.$router.currentRoute.name;
    console.log('run created', this.$router.currentRoute.name);
  },

  methods: {
    selectMenuItem(command) {
      console.log('command', command);
    },
    leftMenuClick(name) {
      console.log('name', name);

      this.$router.replace({ name });
      console.log('replace');
    },
    async quitLogin() {
      localStorage.clear();
      await this.$store.dispatch('user/logout');
      this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  width: 100vw;
  height: 100vh;
}
.left-sider {
  background-color: #191b27;
  min-width: 118px;
  width: 118px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      margin-top: 22px;
      width: 60px;
      height: 74px;
      background-image: url('@/assets/icon/logo.png');
      margin-bottom: 100px;
      cursor: pointer;
    }

    .menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 51px;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        background-color: #383c56;
        border-radius: 16px;
        color: #999fb5;
        gap: 3px;
        cursor: pointer;

        &:nth-child(1) {
          width: 70px;
          height: 70px;
          background-image: url('@/assets/icon/menu/index.png');

          &:hover {
            background-image: url('@/assets/icon/menu/index-active.png');
          }
          &.active {
            background-image: url('@/assets/icon/menu/index-active.png');
          }
        }

        &:nth-child(2) {
          width: 70px;
          height: 70px;
          background-image: url('@/assets/icon/menu/checkout.png');

          &:hover {
            background-image: url('@/assets/icon/menu/checkout-active.png');
          }
          &.active {
            background-image: url('@/assets/icon/menu/checkout-active.png');
          }
        }

        &:nth-child(3) {
          width: 70px;
          height: 70px;
          background-image: url('@/assets/icon/menu/flow.png');

          &:hover {
            background-image: url('@/assets/icon/menu/flow-active.png');
          }
          &.active {
            background-image: url('@/assets/icon/menu/flow-active.png');
          }
        }

        // .text {
        //   font-size: 15px;
        // }

        // &:hover {
        //   background-color: #0080ff;
        //   color: #ffffff;
        // }

        // &.active {
        //   background-color: #0080ff;
        //   color: #ffffff;
        // }
      }
    }
  }

  .bottom {
    margin-bottom: 46px;
    .quit-login {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      color: #999fb5;

      cursor: pointer;
    }
  }
}

.main {
  background-color: #f7f8fb;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 30px;

  overflow: hidden;

  .main-wrapper {
    // overflow-x: hidden;
  }

  .user-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 74px;
    min-height: 74px;
    padding-right: 30px;

    .user {
      display: flex;
      gap: 13px;
      .head {
        width: 43px;
        height: 43px;
        // background: #b0deff;
        border-radius: 50%;
        background-image: url('@/assets/default-header.png');
        background-size: contain;
      }

      .info {
        display: flex;
        flex-direction: column;
        gap: 7px;
        .name {
          font-size: 15px;
          font-weight: 400;
          color: #191b27;
        }

        .phone {
          font-size: 14px;
          font-weight: 400;
          color: #999fb5;
        }
      }
    }
  }
}
</style>
