<template>
  <div class="card">
    <div class="title">{{ title }}</div>
    <div class="content">
      <div class="card-number">{{ cardNumber }}</div>
      <div class="company-name">{{ companyName }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyBlueBankCard',
  props: {
    title: String,
    cardNumber: String,
    companyName: String,
  },

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  flex-direction: column;

  width: 565px;
  height: 276px;
  background-image: url('@/assets/bg/flow/blue-card.png');

  > .title {
    font-size: 16px;
    font-weight: 400;
    color: #FFFFFF;
    margin-left: 80px;
    margin-top: 16px;
    margin-bottom: 48px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .card-number {
      font-family: Akrobat-SemiBold, Akrobat;
      font-size: 28px;
      font-weight: 600;
      color: #191b27;
      margin-bottom: 28px;
      height: 20px;
      line-height: 20px;
    }
    .company-name {
      font-size: 16px;
      font-weight: 400;
      color: #465069;
      margin-bottom: 42px;
    }
  }
}
</style>
