<template>
  <div class="has-sure-container">
    <div class="flow">
      <my-time-line>
        <my-time-line-item :title="dataFlowObj['1_title']" dotTitle="1">
          <span v-html="dataFlowObj['1_content']"></span>
        </my-time-line-item>
        <my-time-line-item :title="dataFlowObj['2_title']" dotTitle="2">
          <span v-html="dataFlowObj['2_content']"></span>
        </my-time-line-item>
        <my-time-line-item :title="dataFlowObj['3_title']" dotTitle="3">
          <span v-html="dataFlowObj['3_content']"></span>
        </my-time-line-item>

        <my-time-line-item :title="dataFlowObj['4_title']" dotTitle="4" :tailHidden="true">
          <span v-html="dataFlowObj['4_content']"></span>
        </my-time-line-item>
      </my-time-line>
    </div>
    <!-- 已确认收款账号显示的卡片 -->
    <div class="has-sure">
      <div class="top">
        <div class="title">收款账户</div>
      </div>

      <div class="main">
        <my-bank-blue-card
          :title="userInfo.bank"
          :cardNumber="userInfo.bank_number"
          :companyName="userInfo.account_name"
        ></my-bank-blue-card>
      </div>
    </div>
  </div>
</template>

<script>
import SureAccountDialog from '@/components/dialog/sureAccountDialog.vue';
import MyBankCard from '@/components/bankCard/myBankCard.vue';
import MyBankBlueCard from '@/components/bankCard/myBankBlueCard.vue';
import MyTimeLine from '@/components/timelines/timeLine.vue';
import MyTimeLineItem from '@/components/timelines/timeLineItem.vue';
export default {
  name: 'HasSureCard',
  components: {
    MyTimeLine,
    MyTimeLineItem,
    MyBankCard,
    MyBankBlueCard,
    SureAccountDialog,
  },

  props: {
    userInfo: {
      type: Object,
      default: {},
    },

    dataFlowObj: {
      type: Object,
      default: {
        '1_title': '',
        '1_content': '',
        '2_title': '',
        '2_content': '',
        '3_title': '',
        '3_content': '',
        '4_title': '',
        '4_content': '',
      },
    },
  },

  computed: {},

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.has-sure-container {
  display: flex;
  gap: 136px;

  width: 100%;

  margin-bottom: 73px;

  background: #ffffff;
  border-radius: 16px;
}

.flow {
  display: flex;
  flex-direction: column;
  width: 778px;
  margin-left: 40px;
  margin-top: 44px;
  margin-bottom: 39px;
}

.has-sure {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 16px;

  margin-top: 44px;
  margin-bottom: 58px;
  margin-right: 33px;

  background: #f7f8fb;
  .top {
    .title {
      margin-bottom: 151px;
      font-size: 24px;
      font-weight: 600;
      color: #191b27;
    }
  }
}
</style>
