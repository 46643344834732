import axios from 'axios';
import md5 from 'md5';
import { getToken } from '@/utils/auth';
import store from '@/store';

function sortByKey(array, key) {
  return array.sort(function (a, b) {
    var x = a[key];
    var y = b[key];
    return x < y ? -1 : x > y ? 1 : 0;
  });
}

const service = axios.create({
  timeout: 30000,
});

service.interceptors.request.use(
  (config) => {
    config.baseURL = process.env.VUE_APP_HOST;

    // config.params = {
    //   app_type: 'questions',
    //   timestrap: '1645862888',
    //   platform: 'admin',
    //   sign: 'test',
    // };

    const time = parseInt(Date.now() / 1000);

    config.params = Object.assign({}, config.params, {
      app_type: 'questions',
      timestrap: time,
      platform: 'admin',
    });
    const keys = Object.keys(config.params);

    const keysArray = [];

    keys.forEach((item) => {
      if (config.params[item] !== undefined) {
        const value = config.params[item].toString().trim();
        keysArray.push({
          label: item,
          value: value,
        });
      }
    });

    const sortKeysArray = sortByKey(keysArray, 'label');

    let hashStrs = '';

    sortKeysArray.forEach((item) => {
      hashStrs += item.label + item.value;
    });

    const hashKey =
      process.env.NODE_ENV === 'production' ? 'BF2CAEC459F801E99B8F4C5FE93952B3' : '1234567890';

    const hash = md5(hashStrs + hashKey);

    config.params.sign = hash;

    if (store.state.user.token) {
      config.headers['Authorization'] = getToken();
    }

    return config;
  },
  (error) => {}
);

service.interceptors.response.use(
  (response) => {
    console.log('service.interceptors.response', response);
    const res = response.data;

    if (response.status !== 200) {
      console.log('response.status不是200', response.statusText);

      return Promise.reject(new Error(response.statusText || 'Error'));
    } else {
      return res;
    }
  },
  (error) => {}
);

export default service;
